import React, { useEffect, useRef, useState } from 'react';
import '../styles/pages/Movie.scss';
import movieLogo from '../assets/cinemas/movieIcon.jpg'
import lifeLogo from '../assets/cinemas/lifeIcon.jpg'
import grupocineLogo from '../assets/cinemas/grupocineIcon.jpg'
import { getMovieById } from '../helpers/getMovies';
import { useNavigate } from 'react-router-dom';
import { MovieObj } from '../utils/classes/Movie';
import Loader from '../components/Loader';
import YouTubeEmbed from '../components/YoutubeEmbed';
import { EventObj } from '../utils/classes/Event';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CinemasEnum } from '../utils/enumerators/Cinemas';

export default function Movie() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const handleOpen = () => setModalStatus(true);
  const handleClose = () => setModalStatus(false);
  const route = window.location.pathname.split('/');
  const id = route[route.findIndex((s) => {
    return s === 'movie';
  }) + 1];
  let navigate = useNavigate();
  const [movie, setMovie] = useState<MovieObj>();
  const [events, setEvents] = useState<EventObj[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventObj[]>([]);
  const [eventSelected, setEventSelected] = useState<EventObj>();
  const mountedRef = useRef(true)


  const [activeCinema, setActiveCinema] = useState<any>();
  const [availableCinemas, setAvailableCinemas] = useState<CinemasEnum[]>();
  const [eventsByComplex, setEventsByComplex] = useState<any>({});
  const [eventsByComplexKeys, setEventsByComplexKeys] = useState<string[]>([]);
  const [defaultFilterValue, setDefaultFilterValue] = useState({ complex: 'Movie', dates: [], format: '2D', language: 'Sub', salas: [], salaSelected: 'Movie Montevideo' })

  useEffect(() => {
    if (id) {
      getMovieById(id).then(
        (item: any) => {
          const m = new MovieObj(item._id, item.keywords.map((w: string) => { return w = w[0].toUpperCase() + w.slice(1, w.length) }).join(' '), item.onlyVac, item.shows, item.poster, item.synopsis, item.directUrl, item.trailer);
          setMovie(m);

          let eventsArr: EventObj[] = [];
          let eventsByComplex: any = {};
          let filteredList: EventObj[] = [];
          m.shows.forEach(show => {
            let complexImg: string;
            let complex: string;
            if (show.complex.includes('Movie')) {
              complexImg = movieLogo;
              complex = show.complex;
            } else if (show.complex.includes('Life')) {
              complexImg = lifeLogo;
              complex = show.complex;

            } else if (show.complex.includes('Grupocine')) {
              complexImg = grupocineLogo;
              complex = show.complex;
            }
            show.dates.forEach(date => {

              let event = new EventObj(show.format + " " + show.lang, complexImg, date);
              event.complex = complex;
              event.format = show.format;
              event.lang = show.lang;
              event.directUrl = m.directUrl[complex.split(' ')[0]];

              if (eventsByComplex[event.complex]) {
                if (eventsByComplex[event.complex][event.title] && eventsByComplex[event.complex][event.title] instanceof Array) {
                  eventsByComplex[event.complex][event.title].push(event);
                } else {
                  eventsByComplex[event.complex][event.title] = [event];
                }
              } else {
                eventsByComplex[event.complex] = {};
                eventsByComplex[event.complex][event.title] = [event];
              }
              //eventsArr.push(event);            

            })
          })
          const eventsByComplexKeys = Object.keys(eventsByComplex)
          setEvents(eventsArr);
          setAvailableFeatures(eventsArr);
          setEventsByComplex(eventsByComplex);
          setEventsByComplexKeys(eventsByComplexKeys);

          let cinemasAvailable: CinemasEnum[] = [];
          const firstCinema = eventsByComplexKeys.forEach((complex: string) => {
            if (complex.toLowerCase().includes('movie')) {
              cinemasAvailable.push(CinemasEnum.MOVIE);
            } else if (complex.toLowerCase().includes('life')) {
              cinemasAvailable.push(CinemasEnum.LIFE);
            } else {
              cinemasAvailable.push(CinemasEnum.GRUPOCINE);
            }
          });
          setAvailableCinemas(cinemasAvailable)

          setActiveCinema(cinemasAvailable[0])


          setIsLoading(false);

        }
      )
    } else {
      navigate(`/notfound`);

    }
    return () => { mountedRef.current = false };

  }, [])

  const onFilterChanged = (value: any) => {

    let filteredList: EventObj[] = [];
    events.forEach((e: EventObj) => {
      const titleSplit = e.title.split(' ');
      const format = titleSplit[0];
      const lang = titleSplit[1];
      const complex = e.complex;
      if (
        value.salaSelected == complex &&
        value.format == format &&
        value.language == lang
      ) {
        filteredList.push(e);
      }
    })
    setFilteredEvents(filteredList);
    if (filteredList.length == 0)
      alert(`No se encontraron funciones con los criterios seleccionados en ${value.salaSelected}`)
  }

  const onEventClicked = (event: any) => {
    event.jsEvent.preventDefault(); // don't let the browser navigate
    const e = event.event;
    const fecha: Date = e.start;
    const complex: string = e._def.extendedProps.complex;
    const titleSplited = e.title.split(' ');
    const format = titleSplited[0];
    const lang = titleSplited[1];

    let eventObj: EventObj = new EventObj(e.title, e.url, fecha);
    eventObj.format = format;
    eventObj.lang = lang;
    eventObj.complex = complex;
    eventObj.directUrl = e._def.extendedProps.directUrl;
    setEventSelected(eventObj)
    setModalStatus(true);

  }
  const onChangeActiveCinema = (event: any) => {
    setActiveCinema(+event.target.id);
  }
  const setAvailableFeatures = (eventArr: EventObj[]) => {
    let formats: string[] = [];
    let languages: string[] = [];
    let cinemas: string[] = [];

    eventArr.forEach((event: EventObj) => {

      if (!formats.includes(event.format)) {
        formats.push(event.format);
      }
      if (!languages.includes(event.lang)) {
        languages.push(event.lang);
      }
      if (!cinemas.includes(event.complex)) {
        cinemas.push(event.complex);
      }
    });
    let df: any = defaultFilterValue;
    df.salas = cinemas;
    setDefaultFilterValue(df)
  }

  const getCinemaByComplex = (complex: String) => {
    if (complex.toLowerCase().includes('movie')) {
      return CinemasEnum.MOVIE
    } else if (complex.toLowerCase().includes('life')) {
      return CinemasEnum.LIFE
    } else {
      return CinemasEnum.GRUPOCINE;
    }
  }
  //const accordionsGenerator = Object.keys(eventsByComplex);

  return (
    <>
      {
        isLoading ?
          <Loader /> :
          <div className='movie-wrapper'>
            {
              movie ?
                <>
                  <div className='intro'>
                    <img alt='Poster' className='poster' src={movie.posterUrl} />
                    <div className='intro-description'>
                      <div className='intro-description-text'>
                        <h1>{movie.title}</h1>
                        <p>{movie.synopsis}</p>
                      </div>
                      <YouTubeEmbed videoId={movie.trailerId} className='intro-description-video' />
                      <div className='row-cinemaslogos'>
                        {
                          availableCinemas?.includes(CinemasEnum.MOVIE) &&
                          <img id="1" className={'row-cinemaslogos-logo ' + (activeCinema != CinemasEnum.MOVIE ? 'disabled' : '')} alt='Movie' src={movieLogo} onClick={onChangeActiveCinema} />
                        }
                        {
                          availableCinemas?.includes(CinemasEnum.GRUPOCINE) &&
                          <img id="3" className={'row-cinemaslogos-logo ' + (activeCinema != CinemasEnum.GRUPOCINE ? 'disabled' : '')} alt='Grupocine' src={grupocineLogo} onClick={onChangeActiveCinema} />
                        }
                        {
                          availableCinemas?.includes(CinemasEnum.LIFE) &&
                          <img id="2" className={'row-cinemaslogos-logo ' + (activeCinema != CinemasEnum.LIFE ? 'disabled' : '')} alt='Life' src={lifeLogo} onClick={onChangeActiveCinema} />
                        }
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{ paddingBottom: '10%' }}>
                    <div className="accordion-wrapper">
                      {
                        eventsByComplexKeys.map((complex: string) => {
                          if (getCinemaByComplex(complex) == activeCinema) {
                            return (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className="accordion-wrapper-title">{complex}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <div className="column-accordion">
                                      {
                                        Object.keys(eventsByComplex[complex]).map((langformat: any) => {
                                          return <Accordion>
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Typography className="accordion-wrapper-title">{langformat}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Typography>
                                                <div className="column-accordion">
                                                  {
                                                    eventsByComplex[complex][langformat].map((event: EventObj) => {
                                                      return <div className='column-accordion-date'>{
                                                        event.date.toLocaleDateString("es-ES", { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                                                      }
                                                        <Button
                                                          onClick={() => {
                                                            window.open(event?.directUrl, '_blank');
                                                          }}
                                                          variant="contained"
                                                        >Comprar</Button>
                                                      </div>;
                                                    })
                                                  }
                                                </div>
                                              </Typography>
                                            </AccordionDetails>
                                          </Accordion>
                                        }
                                        )}

                                    </div>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            );
                          }

                        })
                      }

                    </div>
                  </div>

                </>
                : <></>
            }
          </div>
      }
      {
        modalStatus &&
        <Modal
          open={modalStatus}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modalBox">
            <div><b>Horario:</b> <span>{eventSelected?.date.toLocaleTimeString("es-ES", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</span></div>
            <div><b>Formato:</b> <span>{eventSelected?.format}</span></div>
            <div><b>Lenguaje:</b> <span>{eventSelected?.lang}</span></div>
            <div><b>Complejo:</b> <span>{eventSelected?.complex}</span></div>
            <Button
              onClick={() => {
                window.open(eventSelected?.directUrl, '_blank');
              }}
              variant="contained"
            >Comprar</Button>
          </Box>
        </Modal>
      }
    </>
  )
}
