import { FormatsEnum } from "../enumerators/Formats";
import { LanguagesEnum } from "../enumerators/Languages";

export class Shows{
    complex:string;
    format: FormatsEnum;
    lang: LanguagesEnum;
    dates: Date[] = [];
    constructor(
        complex: string,
        format: string,
        lang: string,
        dates: string[],
    ){
        this.complex = complex;
        switch (format) {
            case '2D':
                this.format = FormatsEnum.DOSD;
                break;
            case '3D':
                this.format = FormatsEnum.TRESD;
                break;
            case '4D':
                this.format = FormatsEnum.CUATROD;
                break;
            default:
                this.format = FormatsEnum.DOSD;
                break;
        }
        switch (lang) {
            case 'Sub':
                this.lang = LanguagesEnum.SUB;
                break;
            case 'Esp':
                this.lang = LanguagesEnum.ESP;
                break;
            case 'Eng':
                this.lang = LanguagesEnum.ENG;
                break;
            default:
                this.lang = LanguagesEnum.SUB;
                break;
        }
        dates.forEach(d =>{
            let date = new Date(d);
            this.dates.push(date);
        })
    }
}