export class EventObj{
    url: string;
    date: Date;
    title: string;
    complex: string = '';
    format: string = '';
    lang: string = '';
    directUrl: string = '';
    constructor(
        title:string,
        url: string,
        date: Date
    ){
        this.title= title;
        this.url = url;
        this.date = date;
    }
}