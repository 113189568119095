import { useState } from "react";
import CardWrapper from "../components/CardWrapper"
import Filter from "../components/Filter"
export default function Home(props: any){
    const [filterFormat, setFilterFormat ] = useState<string>();
    const [filterLanguage, setFilterLanguage ] = useState<string>();
    const [filterCinemas, setFilterCinemas ] = useState<string[]>();

    const onFilterChanged = (value:any)=>{
        setFilterFormat(value.format);
        setFilterLanguage(value.language);
        setFilterCinemas(value.complex);
    }
    return(    
        <>
            <Filter 
                disableComplexSelect={true}
                onFilterChanged={onFilterChanged} 
                styles={{
                    position: 'fixed', 
                    backgroundColor: '#5b3737', 
                    boxShadow: '0px 0px 11px 3px black',
                    transformOrigin: "top",
                    opacity: 0,
                    transform: "rotateX(-100deg)"
                }} 
                animations={props.filterState ? "swing-in-top-fwd" : "swing-out-top-bck"}
            />
            
            <h1 className="main-text">Todas las carteleras de los mejores cines de Uruguay en un solo lugar</h1>

            <CardWrapper filterFormat={filterFormat} filterLanguage={filterLanguage} filterCinemas={filterCinemas}  />
        </>
    )
}
