import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../styles/components/Loader.scss';

export default function Loader(){
    return (
        <div id="cover-spin">
            <Box sx={{ 
                display: 'flex',
                height: '90vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress color='inherit'/>
            </Box>
        </div>
    )
};