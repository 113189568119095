import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import '../styles/components/MovieCard.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSwipeable } from 'react-swipeable';
//Logos
import movieLogo from '../assets/cinemas/movieIcon.jpg';
import lifeLogo from '../assets/cinemas/lifeIcon.jpg';
import grupocineLogo from '../assets/cinemas/grupocineIcon.jpg';
import { MovieObj } from '../utils/classes/Movie';
import { CinemasEnum } from '../utils/enumerators/Cinemas';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface MovieCardProps {
  movie: MovieObj,
  index: number
}

const MovieCard = ({ movie, index }: MovieCardProps) => {

  const [infoStatus, setInfoStatus] = useState(false);

  let navigate = useNavigate();
  const openInfo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setInfoStatus(!infoStatus);
  }

  const showMovie = (event: any, movie: MovieObj) => {
    if (!infoStatus) {
      navigate(`/movie/${movie.id}`);
    }
  }

  let availableCinemas = movie.availableCinemas.map((cinema: CinemasEnum) => {
    if (cinema === CinemasEnum.GRUPOCINE) {
      return <img className='cinemaLogo' src={grupocineLogo} alt='Grupocine' />
    } else if (cinema === CinemasEnum.LIFE) {
      return <img className='cinemaLogo' src={lifeLogo} alt='Life Cinemas' />
    } else if (cinema === CinemasEnum.MOVIE) {
      return <img className='cinemaLogo' src={movieLogo} alt='Movie Center' />
    }
  });

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => {
      eventData.event.preventDefault();
      eventData.event.stopPropagation();
      openInfo(eventData.event);
    },
    onSwipedDown: (eventData) => {
      eventData.event.preventDefault();
      eventData.event.stopPropagation();
      openInfo(eventData.event);
    },
    trackMouse: true
  });

  const handleTouchMove = (event: any) => {
    event.preventDefault();
  };

  return (
    <Card
      onClick={(event: any) => showMovie(event, movie)} key={movie.id}
      sx={{
        width: 345,
        height: 500,
        backgroundImage: `url(${movie.posterUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      className='card'
      id={index.toString()}
    >
      <div
        {...handlers}
        className={"slider " + (infoStatus ? "slide-up-anim" : "slide-down-anim")}
        onTouchMove={handleTouchMove}
      >
        {
          infoStatus ?
            <KeyboardArrowDownIcon fontSize="large" /> :
            <KeyboardArrowUpIcon fontSize="large" />
        }
      </div>

      <div
        className={"info-container " + (infoStatus ? "slide-in-blurred-bottom" : "slide-out-blurred-bottom")}
      >
        <CardContent key={movie.id}>
          <Typography gutterBottom variant="h5" component="div">
            {movie.title}
          </Typography>
          <Typography variant="body2" color="white">
            {movie.synopsis}
          </Typography>
        </CardContent>
        <CardActions>
          {availableCinemas}
        </CardActions>
      </div>
    </Card>
  );
}

export default MovieCard;
