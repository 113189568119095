interface YouTubeEmbedProps {
  videoId: string;
    className: string;
  }
  
const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId, className }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
      <iframe
        className={className}
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
  );
};

export default YouTubeEmbed;
