import MovieCard from "./MovieCard";
import '../styles/components/CardWrapper.scss';
import { MovieObj } from "../utils/classes/Movie";
import { useEffect, useState } from "react";
import { getAllMovies } from "../helpers/getMovies";
import Loader from "./Loader";
import { Shows } from "../utils/classes/Shows";
export default function CardWrapper(props:any){
    const [allMovies, setAllMovies ] = useState<MovieObj[]>([]);
    const [filteredMovies, setFilteredMovies ] = useState<MovieObj[]>([]);
    const [movies, setMovies ] = useState<MovieObj[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() =>{   
             
        if(!props.filterFormat && !props.filterLanguage && !props.filterCinemas){
            getAllMovies().then((response:any)=>{
                let moviesArr: MovieObj[] = [];
                response.forEach((item:any) => {                    
                    const m: MovieObj = new MovieObj(
                        item._id,item.keywords.map(
                            (w:string) => {
                                try{
                                    w = w[0].toUpperCase() + w.slice(1,w.length);
                                }catch(e){
                                    console.error('Can not uppercase word');
                                }finally{
                                    return w;
                                }
                            }).join(' '),item.onlyVac,item.shows,item.poster,item.synopsis, item.directUrl, item.trailer);
                    moviesArr.push(m);
                })            
                setAllMovies(moviesArr.sort((a, b) => b.shows.length - a.shows.length));
                setIsLoading(false);
            })
        }else{
            setIsLoading(true);
            searchMoviesByFilter(props);
        }

    }, [props])

    const allMoviesElement = allMovies.map((value:MovieObj,index:number)=>{
        return <MovieCard movie={value} key={value.id} index={index}/>
    });
    const filteredMoviesElement = filteredMovies.map((value:MovieObj, index:number)=>{
        return <MovieCard movie={value} key={value.id} index={index}/>
    });

    const searchMoviesByFilter = (filterValue: any) => {        
        let filteredList: MovieObj[] = [];
        allMovies.forEach((m:MovieObj) => {
            m.shows.forEach((show: Shows) => {
                
                if(
                    (show.format.length > 0 && show.format == filterValue.filterFormat) &&
                    (show.lang.length > 0 && show.lang == filterValue.filterLanguage) &&
                    (filterValue.filterCinemas.length > 0 && filterValue.filterCinemas.includes(show.complex.split(' ')[0]))
                ){
                    const indexFound = filteredList.findIndex((f) => {
                        return f.title === m.title;
                    });
                    
                    if(indexFound < 0){
                        filteredList.push(m);
                    }
                }
            })
          
        })
        setTimeout(() => {
            setFilteredMovies(filteredList);
            setIsLoading(false);
        }, 700);
    }
    return (
        <>
        {
            isLoading ?
            <Loader/> :
            <div className="wrapper">
            {
                (props.filterFormat || props.filterLanguage || props.filterCinemas) ? filteredMoviesElement: allMoviesElement
            }
        </div>
        }
        </>
    )
}