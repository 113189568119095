import '../styles/pages/Info.scss';
import profilePicture from '../assets/profile.png';
const Info = () => {
    return <div className="info">
        <h1>VoyAlCine</h1>
        <div className="info-warn">
            <p>
                VoyAlCine recolecta informacion de los distintos sitios de cada empresa de cine de Uruguay para que cuando quieras ver una pelicula
                y no sabes en cual de todos los cines hay una funcion que se ajuste a lo que necesitas, tengas todo en un solo lugar.
                La informacion que recolecta VoyAlCine de cada empresa de cine es <strong>informacion publica</strong> accesible a cualquier usuario. 
            </p>
        </div>
        <div className="info-personal">
            <img src={profilePicture} alt="German Alvarez"/>
            <div className='info-personal-desc'>
                <h3>German Alvarez</h3>
                <p>Desarrollador Fullstack con mas de 3 años de experiencia en la industria. Actualmente trabajo en <a target="_blank" href='https://www.linkedin.com/company/altimetrikuy'>Altimetrk</a> y en mis tiempos libres me gusta crear proyectos como este.
                Si queres apoyarme a seguir lanzando proyectos como este podes compartir el proyecto con tus conocidos o en tus redes, eso me ayudaria mucho.</p>
            </div>
        </div>
    </div>
}
export default Info; 