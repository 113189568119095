import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./components/Header";
import NotFoundPage from "./pages/NotFound";
import Movie from "./pages/Movie";
import Precios from "./pages/Precios";
import Info from "./pages/Info";

import '../src/styles/pages/Home.scss'

const App = ()=> {
  const [filterState, setFilterState] = useState(false);
    const onChangeFilterStatus = () => {
        setFilterState(!filterState)
    }

    return (
        <>
            <Router>
            <Header onFilterButtonClicked={onChangeFilterStatus}/>
            <Routes>
                <Route path="/">
                <Route index element={<Home filterState={filterState}/>} />
                <Route path='precios' element={<Precios/>}/>
                <Route path='info' element={<Info/>}/>
                <Route path='movie/:id' element={<Movie/>}/>
                <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
            </Router>
        </>
    );
}

export default App;
