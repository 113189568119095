import { useEffect, useState } from "react";
import { getPrices } from "../helpers/getPrices";
import '../styles/pages/Precios.scss';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import movieLogo from '../assets/cinemas/movieIcon.jpg';
import lifeLogo from '../assets/cinemas/lifeIcon.jpg';
import grupocineLogo from '../assets/cinemas/grupocineIcon.jpg';
const Precio = () => {
    const [pricesByCompany, setPricesByCompany ] = useState<any>();

    useEffect(() =>{   
        getPrices().then((response: any) => {
            setPricesByCompany(response)
        })
    },[])
    return <div className="prices-main">
        <h1>Precios</h1>
        <div className="prices-main-accordion">
            <div className="prices-main-accordion-wrapper">
                    <div className="prices-main-accordion-wrapper-cinema"><img src={movieLogo} alt="Movie"/><p>Movie</p></div>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">2D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            <div className="prices-column-accordion">
                                    {
                                        pricesByCompany && pricesByCompany['Movie']['2D'].map((price: any) =>(
                                            <div className="prices-row-accordion">
                                                <div className="prices-row-accordion-title">{price.descripcion}</div>
                                                <div className="prices-row-accordion-price">$ {price.precio}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">3D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                        <div className="prices-column-accordion">
                                    {
                                        pricesByCompany && pricesByCompany['Movie']['3D'].map((price: any) =>(
                                            <div className="prices-row-accordion">
                                                <div className="prices-row-accordion-title">{price.descripcion}</div>
                                                <div className="prices-row-accordion-price">$ {price.precio}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
            </div>
            <div className="prices-main-accordion-wrapper">
                    <div className="prices-main-accordion-wrapper-cinema"><img src={grupocineLogo} alt="Movie"/><p>Grupocine</p></div>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">2D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="prices-column-accordion">
                                    {
                                        pricesByCompany && pricesByCompany['Grupocine']['2D'] && (
                                            <div className="prices-row-accordion">
                                                <div className="prices-row-accordion-title">{pricesByCompany['Grupocine']['2D'].descripcion}</div>
                                                <div className="prices-row-accordion-price">$ {pricesByCompany['Grupocine']['2D'].precio}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">3D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                        <div className="prices-column-accordion">
                                    {
                                        pricesByCompany && pricesByCompany['Grupocine']['3D'] && (
                                            <div className="prices-row-accordion">
                                                <div className="prices-row-accordion-title">{pricesByCompany['Grupocine']['3D'].descripcion}</div>
                                                <div className="prices-row-accordion-price">$ {pricesByCompany['Grupocine']['3D'].precio}</div>
                                            </div>
                                        )
                                    }
                                </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
            </div>
            <div className="prices-main-accordion-wrapper">
                    <div className="prices-main-accordion-wrapper-cinema"><img src={lifeLogo} alt="Life"/><p>Life</p></div>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">2D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div className="prices-column-accordion">
                                {
                                        pricesByCompany && pricesByCompany['Life']['2D'] && (
                                            <div className="prices-row-accordion">
                                                <div className="prices-row-accordion-title">{pricesByCompany['Life']['2D'].descripcion}</div>
                                                <div className="prices-row-accordion-price">$ {pricesByCompany['Life']['2D'].precio}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography className="prices-main-accordion-wrapper-title">3D</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>
                            <div className="prices-column-accordion">
                                {
                                    pricesByCompany && pricesByCompany['Life']['3D'] && (
                                        <div className="prices-row-accordion">
                                            <div className="prices-row-accordion-title">{pricesByCompany['Life']['3D'].descripcion}</div>
                                            <div className="prices-row-accordion-price">$ {pricesByCompany['Life']['3D'].precio}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </Typography>
                        </AccordionDetails>
                    </Accordion>
            </div>
        </div>
    </div>
}
export default Precio; 