export const getAllMovies = async () => {
    
    const url = process.env.REACT_APP_API_URL + "/movies";
    const resp = await fetch(url);
    const data = await resp.json();
  
    return data;
};

export const getMovieById = async(id:string)=>{
    const url = `${process.env.REACT_APP_API_URL}/movies/${id}`;
    const resp = await fetch(url);
    const data = await resp.json();
    return data;
}