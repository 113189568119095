import { CinemasEnum } from "../enumerators/Cinemas";
import { Shows } from "./Shows";

export class MovieObj{
    id: string;
    title: string;
    onlyVaccinated: boolean;
    shows: Shows[] = [];
    posterUrl: string;
    synopsis: string;
    directUrl: any;
    trailerId: string;
    availableCinemas: CinemasEnum[] = [];
    constructor(
        id: string,
        title:string,
        onlyVac: boolean,
        shows: any,
        posterUrl: string,
        synopsis: string,
        directUrl: any,
        trailerId: string
    ){
        this.id = id;
        this.title = title;
        this.onlyVaccinated = onlyVac;
        this.directUrl = directUrl;
        this.posterUrl = posterUrl;
        this.synopsis = synopsis;
        this.trailerId = trailerId;
        if(shows){
            Object.keys(shows).forEach(k =>{                
                if(k.includes('Life') && !this.availableCinemas.includes(CinemasEnum.LIFE)){
                    this.availableCinemas.push(CinemasEnum.LIFE);
                }else if(
                    k.includes('Movie') && !this.availableCinemas.includes(CinemasEnum.MOVIE)
                ){
                    this.availableCinemas.push(CinemasEnum.MOVIE);
                }else if(
                    k.includes('Grupocine') && !this.availableCinemas.includes(CinemasEnum.GRUPOCINE)
                ){
                    this.availableCinemas.push(CinemasEnum.GRUPOCINE);
                }
                shows[k].forEach((f:any) => {
                    if(Object.keys(f).length > 0){
                        let s = new Shows(k,f.format,f.lang,f.shows);
                        this.shows.push(s);
                    }

                })
            })
        }


    }
    
}