import Checkbox from "@mui/material/Checkbox";
import '../styles/components/Filter.scss';
import movieLogo from '../assets/cinemas/movieIcon.jpg';
import lifeLogo from '../assets/cinemas/lifeIcon.jpg';
import grupocine from '../assets/cinemas/grupocineIcon.jpg';
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Button, InputLabel, makeStyles, MenuItem, Radio, Select, SelectChangeEvent, Typography } from "@mui/material";

const Filter = (props:any) => {
    const [activeControls, setActiveControls] = useState<any>(props.defaultValue ? props.defaultValue: {
        format: '',
        language:'',
        complex:'',
        dates:[],
        salas: [],
        salaSelected: ''
    });
    const [allSalas, setAllSalas] = useState(activeControls.salas);
    const [salasForComplex, setSalasForComplex] = useState(activeControls.salas);
    const [salaSelected, setSalaSelected] = useState(activeControls.salaSelected);

    useEffect(() =>{
        changeSalasSelectOptions(activeControls.complex);
        setSalaSelected(activeControls.salaSelected);
    },[])


    const onSelectChange = (event: SelectChangeEvent) => {
        let active = activeControls;
        setSalaSelected(event.target.value);
        active['salaSelected'] = salaSelected;
        setActiveControls(active)
    };

    const onFormatChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = event.target.value;
        let active = activeControls;
        active['format'] = f;

        setActiveControls(active);   
    }
    const onLanguageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = event.target.value;
        let active = activeControls;
        active['language'] = f;
        setActiveControls(active);    
    }
    const onCinemaChanged =  (complex: string, id: string) => {
                
        let active = activeControls;
        document.getElementById('movieLogo')?.classList.remove('selected')
        document.getElementById('lifeLogo')?.classList.remove('selected')
        document.getElementById('grupoLogo')?.classList.remove('selected')
        
        active['complex'] = complex;
        document.getElementById(id)?.classList.add('selected');
        
        changeSalasSelectOptions(active.complex);
        setActiveControls(active);    
    }

    const onFilterValueChanged = () =>{
        props.onFilterChanged(activeControls);   
    }

    const changeSalasSelectOptions = (complex: string) =>{
        let tempSalas: string[] = [];

        allSalas.forEach((sala:string) =>{
            if(sala.includes(complex)){
                tempSalas.push(sala);
            }
        })
        setSalasForComplex(tempSalas);
        setSalaSelected(tempSalas[0]);
        let active = activeControls;
        active['salas'] = tempSalas;
        active['salaSelected'] = tempSalas[0];
    }

    return(
        <FormControl className={"main-container-filter " + (props.animations)} sx={props.styles}>
                <RadioGroup 
                    row 
                    className="main-container-filter-format"
                    onChange={onFormatChanged}
                    defaultValue={activeControls.format ? activeControls.format : ''}
                >
                    <FormControlLabel  
                        value="2D"
                        control={<Radio/>}
                        label="2D"
                        name='2D'
                    />
                    <FormControlLabel  
                        name='3D'
                        value='3D'
                        label="3D"
                        control={<Radio/>}
                    />
                    <FormControlLabel  
                        name='4D'
                        value='4D'
                        label="4D"
                        control={<Radio/>}
                    />
                </RadioGroup>
                <RadioGroup
                    row
                    onChange={onLanguageChanged}
                    className="main-container-filter-language"
                    defaultValue={activeControls.language ? activeControls.language : ''}
                >
                        <FormControlLabel  
                            value="Esp"
                            control={<Radio/>}
                            label="Esp"
                            name='Esp'
                        />
                        <FormControlLabel  
                            name='Sub'
                            value='Sub'
                            label="Sub"
                            control={<Radio/>}
                        />
                        <FormControlLabel  
                            name='Eng'
                            value='Eng'
                            label="Eng"
                            control={<Radio/>}
                        />
                </RadioGroup>
                <div className="main-container-filter-cinema">
                        <img alt="Movie" id='movieLogo' className={"logo " + (props.defaultValue ? (props.defaultValue.complex == 'Movie' ? 'selected' : '') : '')} src={movieLogo} onClick={()=> onCinemaChanged('Movie','movieLogo')}/>
                        <img alt="Life" id='lifeLogo' className={"logo " + (props.defaultValue ? (props.defaultValue.complex == 'Life' ? 'selected' : '') : '')} src={lifeLogo} onClick={()=> onCinemaChanged('Life','lifeLogo')}/>
                        <img alt="Grupocine" id='grupoLogo' className={"logo " + (props.defaultValue ? (props.defaultValue.complex == 'Grupocine' ? 'selected' : '') : '')} src={grupocine} onClick={()=> onCinemaChanged('Grupocine', 'grupoLogo')}/>
                </div>
                {
                    !props.disableComplexSelect &&
                    <div className="main-container-filter-complex">
                        <InputLabel id="demo-simple-select-helper-label">Complejo</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={salaSelected}
                            label="Complejo"
                            onChange={onSelectChange}
                            sx={{borderColor: 'white', color:'white'}}
                        >
                                {
                                salasForComplex.map((sala:any, index:number)=>{
                                    return <MenuItem value={sala}>{sala}</MenuItem>;
                                })
                                }
                        </Select>
                    </div>
                }
                { 
                    props.enableCalendar && 
                    <div className="main-container-filter-date">
                        <div className="main-container-filter-date-label">
                            <span>Dia / Rango horario</span>
                        </div>
                        <div className="main-container-filter-date-inputs">
                            <input type="date" />
                            <input type="time" />
                            <input type="time" />
                        </div>
                        
                    </div>
                }
                <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    onClick={onFilterValueChanged}
                >
                    Buscar
                </Button>
        </FormControl>
            
    )
}
export default Filter;
